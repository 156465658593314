module.exports = [{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-graphviz","gatsby-remark-embed-video","gatsby-remark-code-titles",{"resolve":"gatsby-remark-images","options":{"maxWidth":786,"backgroundColor":"#ffffff"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.5rem"}},"gatsby-remark-autolink-headers","gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants"]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":786,"backgroundColor":"#ffffff"},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#9D7CBF","showSpinner":false},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-93349937-5","anonymize":true},
    },{
      plugin: require('/Users/dschau/Code/Work/gatsby/gatsby/www/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
