export default [
  0,
  1 / 6,
  1 / 3,
  1 / 2,
  2 / 3,
  5 / 6,
  1,
  4 / 3,
  5 / 3,
  2,
  7 / 3,
  8 / 3,
  3,
]
